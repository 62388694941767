<template>
	<div class="section-6">
		<div class="container-section">
			<img id="square1Sect6" class="square-1" src="/build/images/square-sect-6.svg">
			<img id="circle1Sect6" class="circle-1" src="/build/images/circle-sect-6.svg">

			<div class="title-section">70+ Șabloane de Obiective</div>
			<div class="description-section">În articolele de ajutor, care vin alături de TBF Digital, găsești peste 70 de exemple / șabloane de obiective și rezultate cheie din multiple industrii.</div>

			<div class="tabs-templates">
				<div class="main-tabs">
					<div class="tab-label" v-for="(okr,index) in okrs" v-bind:key="index" v-bind:class="{active: activeTab == index}" @click="changeTab(index)">{{ index }}</div>
				</div>

				<div class="tab-panel" v-for="(okr_list,index) in okrs" v-bind:key="index" v-bind:class="{active: activeTab == index}">
					<carousel-3d :key="index + componentKey" :autoplay="false" :autoplayTimeout="5000" :display="3" :perspective="0" :border="0" :height="400" :width="$mq.below(960) ? ($mq.below(799) ? ($mq.below(500) ? 300 : 338) : 422) : 587" :space="$mq.below(960) ? ($mq.below(799) ? 400 : 500) : 730" :startIndex="2">
						<slide v-for="(okr,i) in okr_list" :index="i" v-bind:key="i">
							<div class="template-obj">
								<div class="main-obj">
									<div class="icon"><img src="/build/images/obj-icn.svg"></div>
									<div class="data">
										<div class="title">{{ okr['nume_obiectiv'] }}</div>
										<div class="desc">{{ okr['descriere_obiectiv'] }}</div>
									</div>
									<div class="progress-data">
										<div class="top">
											<div class="percentage">{{ okr['procentaj'] }}%</div>
											<div class="days">{{ okr['zile_ramase'] }} zile</div>
										</div>
										<div class="progress-bar">
											<div class="progress-bar-completed" :style="'width: '+okr['procentaj']+'%;'"></div>
										</div>
									</div>
								</div>
								<div class="child-obj" v-if="okr['rc_1']">
									<div class="title">{{ okr['rc_1'] }}</div>
									<div class="counter">
										<div class="label">Promisiuni</div>
										<div class="counter-box">{{ Math.floor(Math.random()*(9-1+1)+1) }}</div>
									</div>
									<div class="counter taks">
										<div class="label">Taskuri</div>
										<div class="counter-box">{{ Math.floor(Math.random()*(9-1+1)+1) }}</div>
									</div>
									<div class="value-kr">
										<div class="value">{{ okr['valoare_rc1'] }}</div>
										<div class="currency">{{ okr['unitate_rc1'] }}</div>
									</div>
								</div>
								<div class="child-obj" v-if="okr['rc_2']">
									<div class="title">{{ okr['rc_2'] }}</div>
									<div class="counter">
										<div class="label">Promisiuni</div>
										<div class="counter-box">{{ Math.floor(Math.random()*(9-1+1)+1) }}</div>
									</div>
									<div class="counter taks">
										<div class="label">Taskuri</div>
										<div class="counter-box">{{ Math.floor(Math.random()*(9-1+1)+1) }}</div>
									</div>
									<div class="value-kr">
										<div class="value">{{ okr['valoare_rc2'] }}</div>
										<div class="currency">{{ okr['unitate_rc2'] }}</div>
									</div>
								</div>
								<div class="child-obj" v-if="okr['rc_3']">
									<div class="title">{{ okr['rc_3'] }}</div>
									<div class="counter">
										<div class="label">Promisiuni</div>
										<div class="counter-box">{{ Math.floor(Math.random()*(9-1+1)+1) }}</div>
									</div>
									<div class="counter taks">
										<div class="label">Taskuri</div>
										<div class="counter-box">{{ Math.floor(Math.random()*(9-1+1)+1) }}</div>
									</div>
									<div class="value-kr">
										<div class="value">{{ okr['valoare_rc3'] }}</div>
										<div class="currency">{{ okr['unitate_rc3'] }}</div>
									</div>
								</div>
								<div class="child-obj" v-if="okr['rc_4']">
									<div class="title">{{ okr['rc_4'] }}</div>
									<div class="counter">
										<div class="label">Promisiuni</div>
										<div class="counter-box">{{ Math.floor(Math.random()*(9-1+1)+1) }}</div>
									</div>
									<div class="counter taks">
										<div class="label">Taskuri</div>
										<div class="counter-box">{{ Math.floor(Math.random()*(9-1+1)+1) }}</div>
									</div>
									<div class="value-kr">
										<div class="value">{{ okr['valoare_rc4'] }}</div>
										<div class="currency">{{ okr['unitate_rc4'] }}</div>
									</div>
								</div>
							</div>
						</slide>
					</carousel-3d>
				</div>
			</div>

			<div class="btn-get-templates">
				<button @click="openPricing">Accesează lista de obiective <icon-arrow class="icon-arrow right"/></button>
			</div>
		</div>
	</div>
</template>

<script>
	import IconArrow from '../../assets/landing/Arrow'
	import { gsap } from "gsap";
	import axios from 'axios';
	export default {
		data(){
			return{
				activeTab: 'management',
				allowed_categories: ["Management", "Vanzari", "Marketing", "Finante", "Operational"],
				okrs: {},
				componentKey: 0
			}
		},
		components: {
			IconArrow
		},
		mounted(){
			gsap.from("#square1Sect6", {
				scrollTrigger: {
					trigger: '#square1Sect6',
					start: "top center"
				},
				opacity: 0
			})

			gsap.from("#circle1Sect6", {
				scrollTrigger: {
					trigger: '#circle1Sect6',
					start: "top center"
				},
				opacity: 0
			})

			if(this.$resize && this.$mq.below(800)){
				this.allowed_categories = ["Management", "Vanzari", "Finante"]
			}
		},
		async created() {
			this.getOkrs();
		},
		methods: {
			changeTab(index) {
				this.activeTab = index
				this.componentKey += 1;
			},
			async getOkrs() {
				axios.get("https://digitalbackend.tbf.ro/api/okrs-list").then(res => {
					for (const [key, value] of Object.entries(res.data.data)) {
						if(this.allowed_categories.includes(key)){
							this.okrs[key] = value;
						}
					}
					this.okrs = Object.assign({}, this.okrs);
					this.activeTab = Object.keys(this.okrs).length > 0 ? Object.keys(this.okrs)[0] : '';
				})
			},			
			openPricing(){
				// window.open(this.$router.resolve({name: 'pricing'}).href, '_blank')
				this.$router.push({name: 'pricing'})
			}
		}
	}
</script>